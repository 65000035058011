microflow
  .controller("UIInputSuggestCtrl", [
    "$scope",
    function($scope) {
      $scope.isShowResult = false;
      $scope.showResult = function(isShow) {
        $scope.isShowResult = isShow;
        if (!$scope.$$phase) {
          $scope.$apply();
        }
      };
      $scope.resultSearchStyle = {
        "max-height": "15em",
        overflow: "auto",
        border: "1px solid #eeeeee",
        position: "absolute",
        "z-index": "5",
        width: "100%"
      };
    }
  ])
  .directive("pgInputFilter", [
    function() {
      return {
        restrict: "AE",
        controller: "UIInputSuggestCtrl",
        controllerAs: "insCtrl",
        scope: true,
        compile: function(element, attrs) {
          const arrfilterList = attrs.pgFilterList.split("|");
          const renderList =
            'ng-repeat="item in ' +
            arrfilterList[1] +
            " | filter:" +
            attrs.ngModel +
            '"';

          const elInputSearch = element
            .removeAttr("pg-input-filter")
            .attr("input-search", true)
            .prop("outerHTML");
          const htmlText =
            "<div>" +
            elInputSearch +
            '<div class="pg-search-result list-group list-group-flush" ng-style="resultSearchStyle" ng-show="isShowResult">' +
            '<a class="list-group-item list-group-item-action text-left" ng-click=" ' +
            attrs.ngModel +
            " = item. " +
            attrs.pgSelectModelValueField +
            '" ' +
            renderList +
            ">{{item." +
            arrfilterList[0] +
            "}}</a></div></div>";
          element.replaceWith(htmlText);
        }
      };
    }
  ])
  .directive("inputSearch", [
    "$document",
    function($document) {
      return {
        restrict: "A",
        scope: true,
        link: function(scope, element, attrs) {
          element.on("input", function(event) {
            if (element.val().length > 0) {
              scope.showResult(true);
              $document.one("click", function(event) {
                scope.showResult(false);
              });
            } else {
              scope.showResult(false);
            }
          });
        }
      };
    }
  ]);
